import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField, Box, Button } from '@mui/material';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark, prism } from 'react-syntax-highlighter/dist/esm/styles/prism';

const displayTS = (updatedAt) => {
    const isoDate = updatedAt
    const localDate = new Date(isoDate);
    const nowDate = Date.now();
    const diffTime = Math.abs(nowDate - localDate); // diffTime in milliseconds

    // console.log({ utcDate: utcDate, localDate: localDate, nowDate: nowDate, diffTime: diffTime })

    return { diffTime: diffTime, timeStr: localDate.toLocaleString() }
}

function JobDialog({ name, text, code, textvalue, critical }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDownload = () => {
    // Create a blob with the JSON content
    const blob = new Blob([text], { type: 'application/json' });

    // Create a download link
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = `${name}.json`; // Set the default filename for the download

    // Append the link to the body and trigger the click event to start the download
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Clean up by revoking the object URL after the download
    URL.revokeObjectURL(downloadLink.href);
    document.body.removeChild(downloadLink);
  };

  let buttonStyle = "text";
  let cmd = "Click Here";
  if (textvalue !== '' && textvalue !== undefined && textvalue !== null) {
    cmd = textvalue;
    const lowerCaseString = cmd.toLowerCase();
    const capitalizedString = lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1);
    cmd = capitalizedString;
  }
  let lowerCaseString = name;
  let capitalizedString = name;
  if (name !== undefined && name !== null && name !== '') {
    lowerCaseString = name.toLowerCase();
    capitalizedString = lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1);
  }
  let color = ''
  if (critical === true && name === 'result') {
    color = '#FF6666'
  }
    let jtext = JSON.parse(text);
    if (jtext !== undefined && jtext !== null && jtext.length === 1) {
      let cmd = jtext[0]
      if (cmd.command === "tag") {
        delete cmd.tag;
        text = JSON.stringify(cmd, null, 2);
      }
    }
    return (
    <Box>
      <Button
        variant={buttonStyle}
        onClick={handleClickOpen}
        style={{
          display: 'flex',
          justifyContent:'flex-start',
          fontWeight: 'normal',
          textTransform: 'none',
          textAlign: 'left',
          color: color
        }} // Change to normal font weight and lowercase text
      >
        {cmd}
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle>{capitalizedString}</DialogTitle>
        <DialogContent>
          <SyntaxHighlighter language="json" style={prism}>
            {text}
          </SyntaxHighlighter>
        </DialogContent>
        <DialogActions>
          {/* Add a button to trigger the download for Results and Miners columns only */}
          { capitalizedString !== "Commands"? (
            <div>
                <Button onClick={handleDownload}>Download</Button>
                <Button onClick={handleClose}>OK</Button>
            </div>
          ) :
            <Button onClick={handleClose}>OK</Button>
          }
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export { displayTS, JobDialog };
