import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { TextField, Checkbox, ListItemText, MenuItem } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
// Component that renders a multi-select dropdown with checkboxes
const MultiSelectWithCheckboxes = forwardRef(({ minerGroups, defaultGroup, onChange }, ref) => {
  // Initialize the selected values with the default miner group provided
  const [selectedValues, setSelectedValues] = useState(defaultGroup ? [defaultGroup] : []);

  // This function clears the selected miner groups from the dropdown.
  useImperativeHandle(ref, () => ({
    clearSelections: () => {
      setSelectedValues([]);
      onChange([]); // Notify parent of the change
    }
  }));

  useEffect(() => {
    onChange(defaultGroup ? [defaultGroup] : [])
  }, []);


  
  const handleChange = (event, value) => {
    if (value.includes('Select All')) {
      // If "Select All" is selected
      if (selectedValues.includes('Select All')) {
        // If "Select All" is already selected, deselect all
        if (value.length !== minerGroups.length + 1) { // +1 for "Select All"
          const newValues = value.filter(item => item !== 'Select All') // Uncheck "Select All"
          setSelectedValues(newValues);
          onChange(newValues);
        } 
      } else {
        // Select all options
        setSelectedValues([...minerGroups, 'Select All']);
        onChange([...minerGroups, 'Select All']);
      }
    } else {
      if (selectedValues.includes('Select All')) {
        // Deselect all options if "Select All" was previously selected
        setSelectedValues([]);
        onChange([]);
      } else {
        // Update state with the current groups selected.
        setSelectedValues(value);
        onChange(value);
      }
    }
  };

  

  return (
    <Autocomplete
      multiple
      options={['Select All', ...minerGroups]} // Include "Select All" in the options
      value={selectedValues}
      onChange={handleChange}
      disableCloseOnSelect
      renderOption={(props, option, { selected }) => (
        <MenuItem {...props}>
          <Checkbox checked={selected} />
          <ListItemText primary={option} />
        </MenuItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Search for miner groups"
        />
      )}
      getOptionLabel={(option) => option}
    />
  );
});

export default MultiSelectWithCheckboxes;